import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import KinderCarousel from "../components/KinderCarousel";
import FundraiserCarousel from "../components/FundraiserCarousel";
import PeaceCampCarousel from "../components/PeaceCampCarousel";


function LeadershipVolunteerPage(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <h4>KinderCaminata</h4>
        <p>
          The students of the Occupational Therapy Assistant program volunteered to facilitate a parachute game with the Kindergarteners at Santa Ana College' s KinderCaminata event. KinderCaminata strives to provide an early college experience for children to imagine that college is possible. Each Spring through a community-wide effort, approximately 1000 kindergarten students will arrive at Santa Ana College to learn about c​areer opportunities made possible through college education. The KinderCaminata experience was founded in 1993 to celebrate the life and accomplishments of César Chávez. "¡Sí, se puede!" is Chávez's motto and has been the theme of KinderCaminata since the beginning. (Source:www.sac.edu/ StudentServices/StudentOutreach/Pages/KinderCaminata)
        </p>
        <p>(Please see <a href="resume">resume</a> for volunteer responsibilities)</p>
        <KinderCarousel/>
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View Thank You Letter
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/volunteer_kinder_letter.jpg" alt=""/>
              <hr/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
        <hr/>
        <h4>Families Forward Back to School Fundraiser</h4>
        <p>
          Families Forward exists to help families in need achieve and maintain self-sufficiency through housing, food, counseling, education, and other support services. They work with homeless families to find realistic solutions for sustainable housing and build individualized plans to return each family to self-sufficiency. Furthermore, they also reach out to low-income families to help reduce the pressures that can cause homelessness and help them maintain their stability. (Source: https://www.families-forward.org/about/)
        </p>
        <p>(Please see <a href="resume">resume</a> for volunteer responsibilities)</p>
        <FundraiserCarousel />
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View Flyer
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/lemonade_stand_flyer.jpg" alt=""/>
              <hr/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
        <hr/>
        <h4>Orange County Peace Camp</h4>
        <p>Peace Camp is day camp experience which fosters an understanding of peace, justice, and environmental awareness through an anti-bias curriculum appropriate to the needs of the children and youth involved.</p>
        <p>Campers learn tools to help create a cohesive social community. Safety and respect for self, others, and the environment are explored and practiced through the development of concepts. Skills in conflict management and community building are implemented with an anti- bias focus.</p>
        <p>(Please see <a href="resume">resume</a> for volunteer responsibilities)</p>
        <PeaceCampCarousel/>
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View Certificate
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/peace_camp_cert.jpg" alt=""/>
              <hr/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Content>
    </ContentContainer>
  );
}

export default LeadershipVolunteerPage;