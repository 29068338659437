import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import WorkSamplesCard from "../components/WorkSamplesCards";


function WorkSample3(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <p>&#8226; In this project, I planned and presented to my cohort a meaningful activity by using task analysis in creating a Paper Plate Snake.</p>
        <p>&#8226; Paper materials are simple, open ended, and accessible. I used a paper plate along with other crafting materials such as paint, glue, and markers to create a Paper Plate Snake. </p>
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View Project
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/ws3_pg1.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg2.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg3.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg4.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg5.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg6.jpg" alt=""/>
              <Image fluid src="/pics/ws3_pg7.jpg" alt=""/>
              <hr/>
              <WorkSamplesCard/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Content>
    </ContentContainer>
  );
}

export default WorkSample3;