import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Image from "react-bootstrap/Image";

function ResumePage(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <Image fluid src="/pics/resume_pg1.jpg" alt=""/>
        <Image fluid src="/pics/resume_pg2.jpg" alt=""/>
      </Content>
    </ContentContainer>
  );
}

export default ResumePage;