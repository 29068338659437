import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";

import WorkSegment from "../components/WorkSegment";

function EvaluationsPage(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <WorkSegment/>
      </Content>
    </ContentContainer>
  );
}

export default EvaluationsPage;