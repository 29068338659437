import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Level2FieldWorkCarousel from "../components/Level2FieldWorkCarousel";
import Level2FieldWorkVideoCarousel from "../components/Level2FieldWorkVideoCarousel";
import WorkSamplesCard from "../components/WorkSamplesCards";

function WorkSamples(props) {

  return(
    <div>
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <p>
        This is my Fieldwork Level II experience at Big Fun Recreation Therapy with my Clinical Instructor Gene Hurwin, MA, OTR/L. I learned how to built different obstacle 
        courses for children with sensory integration disorder, motor planning difficulties, and developmental delays. 
        I also learned how to utilize different gymnastic equipment into intervention to make activities more engaging for the clients.
        </p>
        <br />
        <Level2FieldWorkCarousel />
        <br />
        <br />
        <Level2FieldWorkVideoCarousel />
        <hr/>
        <h2>Work Samples</h2>
        <br />

        <WorkSamplesCard/>
      </Content>
    </ContentContainer>
    </div>
  );
}

export default WorkSamples;