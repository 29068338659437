import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function PeaceCampCarousel() {

  return(
    <Carousel interval="2500">
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead_7.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead_8.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead_9.JPG"
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default PeaceCampCarousel;