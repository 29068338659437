import React from 'react';
import Image from "react-bootstrap/Image";
import Content from "../components/Content";
import ContentContainer from "../components/ContentContainer";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function LORPage(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <p>&#8226; These recommendations are from occupational therapy faculty, volunteer supervisor, and parents of students I taught during my time as a preschool teacher.</p>

        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
                Student Occupational Therapy Assistant (SOTA) Club
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Image fluid src="/pics/sota_club_1.jpg" alt="" />
                <Image fluid src="/pics/sota_club_2.jpg" alt="" />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="1">
                ACOTE Letter of Participation
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Image fluid src="/pics/acote_thankyou_letter.jpg" alt="" />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="3">
                Thank You Letter 1
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <Image fluid src="/pics/parent_letter_1.jpg" alt="" />
                <Image fluid src="/pics/parent_letter_2.jpg" alt="" />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="4">
                Thank You Letter 2
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <Image fluid src="/pics/parent_letter_3.jpg" alt="" />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="5">
                Thank You Letter 3
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <Image fluid src="/pics/parent_letter_4.jpg" alt="" />
                <Image fluid src="/pics/parent_letter_5.jpg" alt="" />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Content>
    </ContentContainer>
  );
}

export default LORPage;