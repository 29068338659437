import React from 'react';
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function WorkSamplesCard() {
  const cardExclude = window.location.pathname.slice(-1);


  return(
    <CardDeck>
      { cardExclude !== '1' &&
      <Card>
        <a href="/work-sample-1">
          <Card.Img variant="top" src="/pics/ws_img_1.jpg" />
        </a>
        <Card.Body>
          <Card.Title>Level 1 Fieldwork Observation: Adults</Card.Title>
          <Button className="float-right"
                  variant="outline-secondary"
                  size="sm"
                  href="/work-sample-1">Read More</Button>
        </Card.Body>
      </Card>
      }
      {cardExclude !== '2' &&
      <Card>
        <a href="/work-sample-2">
          <Card.Img variant="top" src="/pics/ws_img_2.jpg"/>
        </a>
        <Card.Body>
          <Card.Title>Scrap Craft Project</Card.Title>
          <Button className="float-right"
                  variant="outline-secondary"
                  size="sm"
                  href="/work-sample-2">Read More</Button>
        </Card.Body>
      </Card>
      }
      {cardExclude !== '3' &&
      <Card>
        <a href="/work-sample-3">
          <Card.Img variant="top" src="/pics/ws_img_3.jpg"/>
        </a>
        <Card.Body>
          <Card.Title>Paper Craft Project</Card.Title>
          <Button className="float-right"
                  variant="outline-secondary"
                  size="sm"
                  href="/work-sample-3">Read More</Button>
        </Card.Body>
      </Card>
      }
    </CardDeck>
  )
}

export default WorkSamplesCard;