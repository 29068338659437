import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function BonggaPicsCarousel() {

  return(
    <Carousel interval="2500">
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/colorado_mountain.JPG"
          alt="Third slide"
          style={{width: "36%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/ph_pic.JPG"
          alt="First slide"
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/Nature2.png"
          alt="Third slide"
          style={{width: "36%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/Nature3.png"
          alt="Third slide"
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default BonggaPicsCarousel;