import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import PowerPointCarousel from "../components/PowerpointCarousel";
import WorkSamplesCard from "../components/WorkSamplesCards";

function WorkSample2(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <p>
          &#8226; In this project, I planned, presented, and taught my cohort a meaningful activity by using task analysis in creating a Talking Stick.
        </p>
        <p>
          &#8226; I used recycled, accessible, and affordable materials such as sticks, yarn, and beads to create my craft.
        </p>
        <PowerPointCarousel />
        <br/>
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View Paper
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/ws2_pg3.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg4.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg5.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg6.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg7.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg8.jpg" alt=""/>
              <Image fluid src="/pics/ws2_pg9.jpg" alt=""/>
              <hr/>
              <WorkSamplesCard/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Content>
    </ContentContainer>
  );
}

export default WorkSample2;