import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function Level2FieldWorkVideoCarousel() {

  return(
    <Carousel interval={null}>
      <Carousel.Item>
        <div style={{width: "100%", height: "100%", paddingBottom: "89%"}}>
          <iframe title="Field Work Level II Video 1" src="https://streamable.com/uwh633" frameborder="0" width="100%" height="100%" allowfullscreen 
          style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", paddingBottom: "2%", overflow: "hidden"}}
          ></iframe>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{width: "100%", height: "100%", paddingBottom: "89%"}}>
          <iframe title="Field Work Level II Video 1" src="https://streamable.com/xsa4nz" frameborder="0" width="100%" height="100%" allowfullscreen 
          style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0%", paddingBottom: "2%", overflow: "hidden"}}
          ></iframe>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

export default Level2FieldWorkVideoCarousel;