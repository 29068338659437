import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function FundraiserCarousel() {

  return(
    <Carousel interval="2500">
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead4.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead5.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead6.JPG"
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default FundraiserCarousel;