import React from 'react';
import Container from 'react-bootstrap/Container';

function Content(props) {

  return(
    <Container className="p-2" fluid={true}>
      {props.children}
    </Container>
  );
}

export default Content;
