import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import WorkSamplesCard from "../components/WorkSamplesCards";


function WorkSample1(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <hr/>
        <p>
          &#8226; In this Fieldwork Observation, we were asked to observe the adult population and write an Activity Analysis.
        </p>
        <p>
          &#8226; For this paper, I observed adults ages 20- 30 years old in a Climbing Gym participating in a leisure activity of Bouldering and Table Tennis.
        </p>
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View work
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Image fluid src="/pics/ws1_pg1.jpg" alt=""/>
              <Image fluid src="/pics/ws1_pg2.jpg" alt=""/>
              <Image fluid src="/pics/ws1_pg3.jpg" alt=""/>
              <Image fluid src="/pics/ws1_pg4.jpg" alt=""/>
              <Image fluid src="/pics/ws1_pg5.jpg" alt=""/>
              <hr/>
              <WorkSamplesCard/>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Content>
    </ContentContainer>
  );
}

export default WorkSample1;