import React from 'react';
import ContentContainer from "../components/ContentContainer";
import Content from "../components/Content";
import BonggaPicsCarousel from "../components/BonggaPicsCarousel";

function HomePage(props) {

  return(
    <ContentContainer title={props.title}>
      <Content>
        <h1 className="home-greeting p-4">Hi, I'm Glynis Rojas.</h1>
        <p style={{textAlign: 'center', marginBottom: '0px'}}>Welcome to my Occupational Therapy Portfolio! I am a Certified Occupational Therapy Assistant and currently furthering my knowledge in the field, taking courses to complete my Bachelors in Science degree in Occupational Studies at Santa Ana College. Here you will find some of the work I have done as a student in the field of Occupational Therapy. This portfolio serves to reflect my growth throughout my professional semesters and give you a more complete picture of who I am.</p>
      </Content>
      <Content>
        <hr/>
        <p>
        I enjoy camping, hiking, climbing, and creative arts. Camping and hiking are two of my most important occupations because it contributes to my overall physical and mental well being. As a  COTA, I hope to utilize my experiences outdoors and be able to provide the same feeling of focus, calmness, and many other health benefits that nature brings to my patients
        </p>
      </Content>
      <BonggaPicsCarousel/>
    </ContentContainer>
  );
}

export default HomePage;