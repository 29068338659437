import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function KinderCarousel() {

  return(
    <Carousel interval="2500">
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead1.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead2.JPG"
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="lead-carousel d-block"
          src="/pics/lead3.JPG"
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default KinderCarousel;