import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";


/* TODO Make dynamic so we can make multiple at will
function WorkSegment(props) {

  const evals = props.evals;


  const listImages = props.evals.images.map((image) =>
    <Image fluid src={ image } alt=""/>
  );

  return(
    <Container className="p-2" fluid={true}>
      { props.title && <h4>{props.evals.title}</h4> }
      { props.evals.description && <p>props.evals.description</p> }
        <Accordion>
          <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
            View work
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              { listImages }
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
    </Container>
  );
}

export default WorkSegment;


 */

function WorkSegment(props) {

  return(
    <Container className="p-2" fluid={true}>
      <hr/>
      <h4>Level II Fieldwork Evaluation</h4>
      <p>
        This evaluation is from my Level 2 Fieldwork supervisor. 
        I worked with adults with developmental disabilities and neurological dysfunctions in planning member lead classes by assisting in creating PowerPoint presentations and researching topics of interest. I also planned activities based on clients interest, keeping in mind their physical and cognitive limitations.
      </p>
      <Accordion>
        <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
          View Evaluation
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Image fluid src="/pics/lvl2eval_pg1.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg2.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg3.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg4.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg5.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg6.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg7.jpg" alt=""/>
            <Image fluid src="/pics/lvl2eval_pg8.jpg" alt=""/>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
      <hr/>
      <h4>OTA Second Semester Evaluation</h4>
      <p>
        This evaluation is from my OTA 102 and OTA 102 Lab professor during my second semester. OTA 102 and OTA 102 Lab discusses human Psychosocial Function & Dysfunction, as well as hands on learning in Psychosocial Components of Occupation.
      </p>
      <Accordion>
        <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
          View Evaluation
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Image fluid src="/pics/eval1.jpg" alt=""/>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
      <hr/>
      <h4>Level I Fieldwork Evaluation</h4>
      <p>
        This evaluation is from my Level 1 Fieldwork supervisor at Integrity House.
        I worked with a member with Cerebral Palsy in completing her resume and searched for possible job opportunities that can accommodate her needs.
        I also planned activities based on her interest, keeping in mind her physical and cognitive limitations, such as playing simple board games with other members.
      </p>
      <Accordion>
        <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
          View Evaluation
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Image fluid src="/pics/eval2.jpg" alt=""/>
            <Image fluid src="/pics/eval3.jpg" alt=""/>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
      <hr/>
      <h4>Work Evaluation</h4>
      <p>
        This evaluation is from the Director of Foothills Preschool, where I worked as a Lead Teacher for 3 years.
      </p>
      <Accordion>
        <Accordion.Toggle as={Button} variant="outline-secondary" eventKey="0">
          View Evaluation
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Image fluid src="/pics/eval4.jpg" alt=""/>
            <Image fluid src="/pics/eval5.jpg" alt=""/>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Container>
  );
}

export default WorkSegment;