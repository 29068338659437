import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function Level2FieldWorkCarousel() {

  return(
    <Carousel interval="2500">
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_00.JPG"
          alt=""
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_01.JPG"
          alt=""
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_02.JPG"
          alt=""
          style={{width: "33.8%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_03.JPG"
          alt=""
          style={{width: "33.8%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_04.JPG"
          alt=""
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_05.JPG"
          alt=""
          style={{width: "33.8%", height: "100%"}}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="bongga d-block"
          src="/pics/FW2_06.JPG"
          alt=""
          style={{width: "60%", height: "100%"}}
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default Level2FieldWorkCarousel;