import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

function PowerPointCarousel() {

  return(
    <Carousel interval="3000">
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_1.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_2.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_3.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_4.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_5.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_6.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_7.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_8.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_9.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_10.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_11.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_12.png"
          alt="slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="powerpoint d-block"
          src="/pics/pp_13.png"
          alt="slide"
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default PowerPointCarousel;