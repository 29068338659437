import React from 'react';
import Container from "react-bootstrap/Container";

function ContentContainer(props) {

  return(
    <Container className="p-5" style={containerStyles}>
      { props.title && <h2>{props.title}</h2> }
      { props.children}
    </Container>
  );
}

const containerStyles = {
  backgroundColor: '#F9F8F8',
};

export default ContentContainer;