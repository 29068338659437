import React from 'react';
import {BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import Jumbotron from "react-bootstrap/Jumbotron";
import './App.css';

import HomePage from "./pages/HomePage";
import ResumePage from "./pages/ResumePage";
import EvaluationsPage from "./pages/EvaluationsPage";
import WorkSamplesPage from "./pages/WorkSamplesPage";
import LORPage from "./pages/LORPage";
import LeadershipVolunteerPage from "./pages/LeadershipVolunteerWork";
import WorkSample1 from "./pages/WorkSample1Page";
import WorkSample2 from "./pages/WorkSample2Page";
import WorkSample3 from "./pages/WorkSample3Page";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Glynis Rojas',
      headerLinks: [
        {title: 'Home', path: '/'},
        {title: 'About', path: '/about'},
        {title: 'Contact', path: '/contact'},
      ],
      home: {
        title: '',
        intro_msg: 'Long message goes here'
      },
      resume: {
        title: 'Resume',
        resume_image: '/.jpg'
      },
      evaluations: {
        title: 'Evaluations',
        evals: [
          {
            title: 'Second Semester Evaluation',
            description: 'This evaluation is from my OTA 102 and OTA 102 Lab professor during my second semester. OTA 102 and OTA 102 Lab discusses human Psychosocial Function & Dysfunction, as well as hands on learning in Psychosocial Components of Occupation.',
            images: [
              '/pics/eval1.jpg'
            ]
          }
        ]
      },
      workSamples: {
        title: 'Fieldwork Experience',
      },
      workSample1: {
        title: 'Level 1 Fieldwork Observation: Adults'
      },
      workSample2: {
        title: 'Scrap Craft Project'
      },
      workSample3: {
        title: 'Paper Craft Project'
      },
      lettersOfRecommendation: {
        title: 'Letters Of Recommendations'
      },
      leadershipAndVolunteer: {
        title: 'Leadership & Volunteer Work'
      }
    };
  }


  render(){
    return (
      <Router >
        <Container id="header" className="p-0" fluid={true}>
          <Jumbotron id="jumbos" className="p-0" style={jumbotronStyles}>
            {/* Header and Navbar Container*/}
            <Container >
              <h1 className="header text-center">Glynis Rojas</h1>
              <p className="text-center">
                "May your hands be an extension of your heart and may you do the work of love with them." - Aja Monet
              </p>
              <Container >
                <Navbar bg="transparent" expand="md" >
                  <Navbar.Toggle className="border-0" aria-controls='navbar-toggle' />
                  <Navbar.Collapse id="navbar-toggle">
                    <Nav className="justify-content-center" style={{ width: "100%" }}>
                      <hr />
                      <Link className="nav-link border-bottom" to="/" style={navbarStyles}>Home</Link>
                      <hr />
                      <Link className="nav-link border-bottom" to="/resume" style={navbarStyles}>Resume</Link>
                      <hr />
                      <Link className="nav-link border-bottom" to="/evaluations" style={navbarStyles}>Evaluations</Link>
                      <hr />
                      <Link className="nav-link border-bottom" to="/work-samples" style={navbarStyles}>Fieldwork</Link>
                      <hr />
                      <Link className="nav-link border-bottom" to="/letters-of-recommendation" style={navbarStyles}>
                        Letters of Recommendation
                      </Link>
                      <hr />
                      <Link className="nav-link border-bottom" to="/leadership-and-volunteer" style={navbarStyles}>
                        Leadership & Volunteer Work
                      </Link>
                      <hr />
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </Container>
          </Jumbotron>
        </Container>
        {/* Main Content */}
        <Container id="main-content" className="p-0" fluid={true} style={bgImageStyles}>
          <Container id="content-container">
            <Route
              path="/"
              exact render={()=>
                <HomePage
                  title={this.state.home.title}
                />}
              subTitle={this.state.home.subTitle}
              text={this.state.home.text}
            />
            <Route
              path="/resume"
              exact render={()=>
              <ResumePage
                title={this.state.resume.title}/>}
            />
            <Route
              path="/evaluations"
              exact render={()=>
              <EvaluationsPage
                title={this.state.evaluations.title}/>}
            />
            <Route
              path="/work-samples"
              exact render={()=>
              <WorkSamplesPage
                title={this.state.workSamples.title}/>}
            />
            <Route
              path="/letters-of-recommendation"
              exact render={()=>
              <LORPage
                title={this.state.lettersOfRecommendation.title}/>}
            />
            <Route
              path="/leadership-and-volunteer"
              exact render={()=>
              <LeadershipVolunteerPage
                title={this.state.leadershipAndVolunteer.title}/>}
            />
            <Route
              path="/work-sample-1"
              exact render={()=>
              <WorkSample1
                title={this.state.workSample1.title}/>}
            />
            <Route
              path="/work-sample-2"
              exact render={()=>
              <WorkSample2
                title={this.state.workSample2.title}/>}
            />
            <Route
              path="/work-sample-3"
              exact render={()=>
              <WorkSample3
                title={this.state.workSample3.title}/>}
            />

          </Container>
        </Container>
        <Container className="p-0 remainder" fluid={true} >
        </Container>
      </Router>
    );
  }
}

const jumbotronStyles = {
  color: 'white',
  backgroundColor: '#baabab',
  marginBottom: '0px'
};

const navbarStyles = {
  color: 'white',
};

const bgImageStyles = {
  backgroundImage: `url(/bg_image.jpg)`,
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  minHeight: '1180px',
  backgroundSize: 'auto',
  position: 'relative',
  backgroundColor: '#BAABAB',
};

export default App;
